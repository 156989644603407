import {MIX_COLORS} from "../methods/mixer.method";
import {GET_CONTRAST_COLOR} from "../methods/contrast.method";

export function HANDLE_COLOR_COLUMNS(columns, baseColor) {
    columns.forEach(column => {
        const MIX_COLOR = column.dataset.mode === 'light' ? '#ffffff' : '#000000';
        const WEIGHT = parseInt(column.dataset.weight);
        const COLOR_TEXT = column.querySelector('[data-color]');
        const MIXED_COLOR = MIX_COLORS(MIX_COLOR, baseColor, WEIGHT);
        const CONTRAST_COLOR = GET_CONTRAST_COLOR(MIXED_COLOR);

        column.setAttribute('style', `--bg-color: ${MIXED_COLOR}; --contrast-color: ${CONTRAST_COLOR};`)
        COLOR_TEXT.innerHTML = MIXED_COLOR;
    })
}
