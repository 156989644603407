import {SET_FONT_SIZE, SET_LINE_HEIGHT, SET_TEXTS} from "../methods/fonts.method";

export function HANDLE_FONT_COLUMNS(cols) {
    cols.forEach(column => {
        const TEXT = column.querySelector('[data-text]');
        const SIZE_INPUT = column.querySelector('[data-size-input]');
        const LINE_INPUT = column.querySelector('[data-line-input]');

        SET_TEXTS(column, TEXT);

        window.addEventListener('resize', () => {
            SET_TEXTS(column, TEXT);
        });

        if (SIZE_INPUT) {
            SIZE_INPUT.addEventListener('keyup', () => {
                SET_FONT_SIZE(SIZE_INPUT, TEXT)
                SET_TEXTS(column, TEXT);
            });

            SIZE_INPUT.addEventListener('change', () => {
                SET_FONT_SIZE(SIZE_INPUT, TEXT)
                SET_TEXTS(column, TEXT);
            });

        }

        if (LINE_INPUT) {
            LINE_INPUT.addEventListener('keyup', () => {
                SET_LINE_HEIGHT(LINE_INPUT, TEXT)
                SET_TEXTS(column, TEXT);
            });
            LINE_INPUT.addEventListener('change', () => {
                SET_LINE_HEIGHT(LINE_INPUT, TEXT)
                SET_TEXTS(column, TEXT);
            });
        }
    })
}
