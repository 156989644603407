const DECIMAL_TO_HEX = hex => ("0" + parseInt(hex).toString(16)).slice(-2);
const HEX_TO_DECIMAL = hex => parseInt(hex, 16);

export function MIX_COLORS(baseColor, color_2, weight) {
    weight = (typeof (weight) !== 'undefined') ? weight : 50;

    baseColor = baseColor.replace(/#/g, '');
    color_2 = color_2.replace(/#/g, '');

    const BASE_COLOR = color_2 === '' ? "#ffffff" : "#" + color_2;

    let color = "#";

    for (let i = 0; i <= 5; i += 2) {
        const V1 = HEX_TO_DECIMAL(baseColor.substr(i, 2))
        const V2 = HEX_TO_DECIMAL(color_2.substr(i, 2))

        let val = DECIMAL_TO_HEX(Math.round(V2 + (V1 - V2) * (weight / 100.0)));
        while (val.length < 2) val = '0' + val;
        color += val;
    }

    return color.toLowerCase().includes('nan') ? BASE_COLOR : color;
}
