export function SET_FONT_SIZE(sizeInput, text) {
    if (sizeInput.value <= 0 || sizeInput.value === '') {
        sizeInput.value = 16;
        text.style.fontSize = '16px';
    } else {
        text.style.fontSize = sizeInput.value + 'px';
    }
}

export function SET_LINE_HEIGHT(lineInput, text) {
    text.style.lineHeight = lineInput.value;
}

export function SET_TEXTS(column, text) {
    const SIZE_TEXT = column.querySelector('[data-size]');
    const LINE_TEXT = column.querySelector('[data-line]');

    SIZE_TEXT.innerHTML = window.getComputedStyle(text, null).getPropertyValue('font-size');
    LINE_TEXT.innerHTML = window.getComputedStyle(text, null).getPropertyValue('line-height');
}
