import {HANDLE_COLOR_COLUMNS} from "./js/handler/color.handler";
import {HANDLE_COPY_TO_CLIPBOARD} from "./js/handler/copy.handler";
import {HANDLE_FONT_COLUMNS} from "./js/handler/font.handler";


const FONT_COLUMNS = document.querySelectorAll('[data-font]');

HANDLE_FONT_COLUMNS(FONT_COLUMNS);


const COLOR_PICKER = document.querySelector('[type="text"]');
const COLOR_COLUMNS = document.querySelectorAll('[data-weight]');

HANDLE_COPY_TO_CLIPBOARD(COLOR_COLUMNS);
COLOR_PICKER.addEventListener('keyup', () => HANDLE_COLOR_COLUMNS(COLOR_COLUMNS, COLOR_PICKER.value));


