function HANDLE_SUCCESS(textBlock) {
    textBlock.setAttribute('data-success', '');
    setTimeout(() => {
        textBlock.removeAttribute('data-success');
    }, 700)
}

function SECURED_COPY(textBlock) {

    navigator.clipboard.writeText(textBlock.querySelector('[data-color]').innerText).then(function () {
        HANDLE_SUCCESS(textBlock);
    }, function (err) {
        console.log(err);

    });
}

function UNSECURED_COPY(textBlock) {
    const textArea = document.createElement("textarea");
    textArea.value = textBlock.querySelector('[data-color]').innerText;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        HANDLE_SUCCESS(textBlock);
    } catch (err) {
        console.error('Unable to copy to clipboard', err);
    }
    document.body.removeChild(textArea);
}

export function HANDLE_COPY_TO_CLIPBOARD(columns) {
    columns.forEach(column => {
        const TEXT_BLOCK = column.querySelector('.text');
        TEXT_BLOCK.addEventListener('click', () => {

            if (window.isSecureContext && navigator.clipboard) {
                SECURED_COPY(TEXT_BLOCK);
            } else {
                UNSECURED_COPY(TEXT_BLOCK);
            }

        })
    })
}
